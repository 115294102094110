<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id_"> ({{ id_ }})</small>
        {{ title }}
      </template>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true, readonly, hideDetails: 'auto' }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <a-btn-send-code
          v-if="getAccess('supply.status') && data.status == 1"
          :id="id_"
          :api="api"
          @success="fetchData(id_)"
          :title="'Подпись(SMS)'"
          :beforeSend="beforeSend"
        />
        <v-spacer />
        <a-btn-save :disabled="readonly" @click="_submit()"></a-btn-save>
        <a-btn-delete v-if="canDelete && id" :disabled="readonly" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-model="removeDialogShow" @remove="_remove(api, id)" :title="removeTitle" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    m: Object,
    configForm: { type: Object, default: () => {} },
    hasData: { type: Boolean, default: false },
    initData: { type: Object, default: () => {} },
    opt: { type: [String, Object], default: () => {} },
    params: { type: Object, default: () => {} },
  },
  data() {
    return {
      model: [],
      idEdit: 0,
      suEditShow: false,
      removeDialogShow: false,
      type: null,
      loaded: false,
      options: [],
      watchModel: null,
      fieldData: {},
      id_: 0,
    };
  },
  computed: {
    _opt() {
      if (typeof this.opt === "object") {
        return this.opt;
      } else {
        let res = {};
        (this.opt || "").split(",").forEach((e) => {
          res[e] = true;
        });
      }
    },
    config() {
      if (this.configForm?.head) return this.configForm.head;
      const type = this.configForm?.type || "default";
      return JSON.parse(JSON.stringify(this.m?.config?.[type] || {}));
    },
    api() {
      return this.configForm?.api || this.m?.api || null;
    },
    title() {
      const title = this.configForm?.title || this.m?.title || "";
      let res = "";
      if (this.id_) res = title + (this.readonly ? " [Просмотр]" : " [Редактирование]");
      else res = title + " [Создание]";
      return res;
    },

    canDelete() {
      return this.getAccess("supply.delete", { data: this.data }) && this.data.status === 1;
    },
    readonly() {
      if (this.data.status == 2) return true;
      if (this._opt.readonly) return true;
      if (this.params?.func?.readonly) {
        try {
          return eval(this.params.func.readonly)(this.data);
        } catch (error) {}
      }
    },
    removeTitle() {
      return this.m?.removeTitle || "Вы действительно хотите удалить это?";
    },
  },
  created() {
    this.loaded = false;
    this.id_ = this.id;
    this.updateData(this.id_);
    if (!this.id_) {
      this.loaded = true; ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  watch: {},
  methods: {
    async beforeSend() {
      console.log("call beforeSend");
      let res = await this.validateData(this.data, [{ name: "document", title: "Фото документов", validator: ["req"] }], true);
      console.log(res, this.data);
      return res;
    },
    getTitle() {
      //  {{ id ? title : "Новый элемент '" + title + "'" }}
      let res = "";
      if (this.id_) res = this.title + (this.readonly ? " [Просмотр]" : " [Редактирование]");
      else res = this.title + " [Создание]";
      return res;
    },
    _submit() {
      if (this.readonly) return;
      const fields = this.configForm.head.fieldsPost || "";
      this.submit(false, fields);
    },
    postData() {},

    afterFetchData(r) {
      // this.calcModel(this.data?.type);
      let t = 0;
      if (this.m?.config?.watch) {
        t = this.data?.[this.m.config.watch] || 0;
        //console.log("config", t, this.config);
      }
      this.getModel(t);
      this.loaded = true;
    },
    getModel(t) {
      console.log("getmodel");
      let config = this.config;
      if (t) config = JSON.parse(JSON.stringify(this.m?.config?.[t] || this.config));
      if (this.params?.fieldsRO) config.fieldsRO += (config.fieldsRO ? "," : "") + this.params.fieldsRO;
      this.watchModel = config?.watch || null;
      let model = this.calcModelConfig(config);
      if (true)
        model.forEach((el) => {
          if (el.hiddenCreate && !this.data?.id) {
            el.hidden = true;
          }
        });
      this.fillFormFromModel(model);
      this.model = model;
      if (!this.id_) this.inputData(this.initData);
    },
    afterSave(data, status) {
      console.log({ data, status });
      if (status && this.id) this.$emit("input");
      let id = data.id;
      if (!this.id_) this.id_ = id;
      if (!this.id_ && this.m?.routeName) this.$router.push({ name: this.m.routeName, params: { id } });
    },

    inputData(d = {}) {
      if (d)
        for (const name in d) {
          let m;
          if ((m = this.model.find((m) => m.name == name))) {
            m.readonly = true;
          }
          this.$set(this.data, name, d[name]);
          this.$set(this.errors, name, null);
        }
    },
    async _remove(api, id) {
      if (!api || !id) return;
      await this.remove(api, id);
      this.$emit("input");
    },
  },
};
</script>
